<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <Dialog
        :header="transaction ? 'Transaccion ' + transaction.id : ''" 
        v-model:visible="transactionView" 
        :style="{width: '50vw'}" 
        :modal="true">
            <p>Concepto: {{transaction ? transaction.concept : ''}}</p>
            <p>Sucursal: {{transaction ? transaction.branch : ''}}</p>
            <p>Fecha: {{transaction ? transaction.submitTimeUtc : ''}}</p>
            <p>Importe: {{transaction ? transaction.authorized_amount : ''}}</p>
            <p>Tipo Tarjeta: {{transaction ? transaction.card_type : ''}}</p>
            <p>Tarjeta: {{transaction ? transaction.card_number : ''}}</p>
            <p>Cliente: *PROXIMAMENTE*</p>
            <p>Direccion: *PROXIMAMENTE*</p>
        </Dialog>
        <div class="col-12">
            <div class="card">
                <Toast />
                <h2>Transacciones</h2>
                <BasicFormToolbar @new="openNew" @list="list" @export="exportCSV" :actions="['new', 'list', 'export']" />
                <div class="grid">
                    <div class="col-12">
                        <Fieldset :toggleable="true" legend="Filtros">
                            <div class="p-fluid p-formgrid grid">
                                <FormDropdown label="Tipo de Reporte" wrapperClass="field col-12" v-model="report_type" :options="[{id: 1, name: 'Transacciones Virtuales'},{id: 2, name: 'Transacciones Fisicas'},{id: 3, name: 'Transacciones de Link de Pago'},{id: 4, name: 'Todas las Transacciones'}]" optionLabel="name" optionValue="id"/>
                                <FormDropdown label="Sucursal" wrapperClass="field col-4" v-model="branch_val" :options="branches" optionLabel="branch_name" optionValue="id_branch"/>
                                <FormCalendar label="Fecha Inicial" wrapperClass="field col-4" v-model="initial_date" :valid="validate.validations.initial_date" />
                                <FormCalendar label="Fecha Final" wrapperClass="field col-4" v-model="final_date" :valid="validate.validations.final_date" />
                                <FormDropdown v-if="report_type == 1" label="Estatus"  wrapperClass="field col-4" v-model="status" :options="statuses" optionLabel="id" optionValue="id"/>
                                <FormDropdown v-if="report_type == 2 || report_type==4"  :tooltip="'Valido en Terminal Fisica'" label="Tipo" wrapperClass="field col-4" v-model="type" :options="physical_types" optionLabel="text" optionValue="id"/>
                                <FormDropdown v-if="report_type == 4"  :disabled="type=='/payment/refund'" label="Estatus" wrapperClass="field col-4" v-model="status" :options="statuses" optionLabel="name" optionValue="id"/>
                            </div>
                        <div class="p-fluid p-formgrid grid">
                                <FormInputText :tooltip="'Total recaudado por transacciones'" :readonly="true" v-model="total" class="field col-2" label="Total"  />
                                <FormInputText :tooltip="'Comisiones por el servicio de Business Wallet'" :readonly="true" v-model="comissions" class="field col-2" label="Comisiones"  />
                                <FormInputText :tooltip="'IVA generado por comisiones del %16'" :readonly="true" v-model="iva" class="field col-2" label="IVA"  />
                                <FormInputText :tooltip="'Pago a recibir en tu cuenta'" :readonly="true" v-model="neto" class="field col-2" label="Pago Neto"  />
                            </div>
                        </Fieldset>
                    </div>
                    <div class="col-12 scrollable-table" style="overflow-x: scroll;">
                        <Fieldset :toggleable="true" legend="Datos">
                            <BasicDatatable 
                            fontsize='small'
                            :exportFilename="filename"
                            :selectionMode="'single'" 
                            :rows="entities" 
                            :headers="headers" 
                            :rowGroupMode="'subheader'" 
                            :groupRowsBy="groupRowsBy"
                            :rowSubgroupMode="'SUM'"
                            :subgroupRowsBy="subgroupRowsBy"
                            :contextMenu="true"
                            :sortable="false"
                            @refund="refund" 
                            @reversal="reversal"
                            @view-details="viewDetails"
                            @capture="capture"
                            @void="voidTransaction"
                            @send="send"
                            @ticket="ticket"
                            @filtered-footer="getTotals"
                            :menuModel="menuModel" />
                        </Fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { Rule, validate, ErrorToast } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import FormDropdown from "../../../components/general/FormDropdown.vue";
import FormCalendar from "../../../components/general/FormCalendar.vue";

import Session from '../../../mixins/sessionMixin';
import { BWPhysicalSale } from '../../../models/custom/business_wallet/BWPhysicalSale';
import { PaymentParameters } from '../../../models/custom/business_wallet/PaymentParameters';
import { BWInvoice } from '../../../models/custom/business_wallet/BWInvoice';
import { ERPUser } from '../../../models/seguridad/ERPUser';
import { PAYMENT_DICTIONARY_CAPTURE, 
    PAYMENT_DICTIONARY_REFUND, 
    PAYMENT_DICTIONARY_REVERSAL,
    LINK_TRANSACTION_HEADERS, 
    PHYSICAL_TRANSACTION_HEADERS, 
    VIRTUAL_TRANSACTION_HEADERS ,ALL_TRANSACTION_HEADERS } from '../../../utilities/BW_UTILITIES';
import FormInputText from '../../../components/general/FormInputText.vue';

export default {
    mixins: [Session],
    data() {
        return {
            transaction: null,
            entity: null,
            entities: [],
            transactionView: false,
            initial_date: new Date(),
            final_date: new Date(),
            branch_val: null,
            report_type: 1,
            type: '/payment/sale',
            status: 'AUTHORIZED',
            rules: [new Rule({ name: 'initial_date' }), new Rule({ name: 'final_date' })],
            validate: {
                valid: false,
                validations: {
                    initial_date: null,
                    final_date: null,
                    type: null,
                },
            },
            physical_types: [
                { id: '/payment/sale', text: 'VENTA' },
                { id: '/payment/refund', text: 'REEMBOLSO' }
            ],
            branches: [],
            loading: false,
            total: '$0.00',
            comissions: '$0.00',
            neto: '$0.00',
            iva: '$0.00'
        };
    },
    components: { FormCalendar, Loader, FormDropdown, BasicFormToolbar, BasicDatatable, FormInputText },
    watch: {
        report_type(newValue) {
            this.entities = [];
            this.status = newValue == 1 ? 'AUTHORIZED' 
            : newValue == 2 ? 'APPROVED' 
            : newValue == 3 ? 'ACCEPT' 
            : newValue == 4 ? 'AUTHORIZED' 
            : null;
        }
    },
    computed: {
        filename() {
            let file = this.report_type == 1  ? "TRANSACCIONES_FISICAS"
                : this.report_type == 2 ? "TRANSACCIONES_FISICAS"
                : this.report_type == 3 ? "TRANSACCIONES_LINK_PAGO"
                : "EXCEL";
            return file;
        },
        statuses() {
            if (this.report_type == 1)
                return [{id: 'AUTHORIZED'},{id: 'DECLINED'},{id: 'PARTIAL_AUTHORIZED'},{id: 'AUTHORIZED_PENDING_REVIEW'},{id: 'AUTHORIZED_RISK_DECLINED'},{id: 'PENDING_AUTHENTICATION'},{id: 'PENDING_REVIEW'},{id: 'INVALID_REQUEST'}]
            else if (this.report_type == 2)
                return [{ id: 'APPROVED', name: 'APROBADA'},{ id: 'DENIED', name: 'DENEGADA'},{ id: 'ERROR', name: 'ERROR'}]
            else if (this.report_type == 3)
                return [{id: 'ACCEPT'},{id: 'DECLINE'},{id: 'ERROR'}]
            else return    [{id: 'AUTHORIZED',name: 'APROBADA,ACCEPT,APPROVED'},{id: 'DECLINED',name: 'DECLINED,DENIED,DECLINED'},{id: 'PARTIAL_AUTHORIZED' ,name:'PARTIAL_AUTHORIZED'},{id: 'AUTHORIZED_PENDING_REVIEW',name: 'AUTHORIZED_PENDING_REVIEW'},{id: 'AUTHORIZED_RISK_DECLINED',name: 'AUTHORIZED_RISK_DECLINED'},{id: 'PENDING_AUTHENTICATION',name:'PENDING_AUTHENTICATION'},{id: 'PENDING_REVIEW',name:'PENDING_REVIEW'},{id: 'INVALID_REQUEST',name:'INVALID_REQUEST,ERROR,ERROR'}];
        },
        groupRowsBy() {
            return this.report_type == 1 ? "date" 
            : this.report_type == 2 ? "trxDate" 
            : this.report_type == 3 ? "date" 
            : this.report_type == 4 ? "date" 
            :null
        },
        subgroupRowsBy(){
            return this.report_type == 1 ? "authorized_amount" 
            : this.report_type == 2 ? "trxAmount" 
            : this.report_type == 3 ? "auth_amount" 
            : this.report_type ==4? "amount"
             :null
        },
        headers() {
            if (this.report_type == 1) 
                return VIRTUAL_TRANSACTION_HEADERS[this.status] ? VIRTUAL_TRANSACTION_HEADERS[this.status] : [];
            else if (this.report_type == 2) {
                let physicalHeaders = PHYSICAL_TRANSACTION_HEADERS[this.type] ? PHYSICAL_TRANSACTION_HEADERS[this.type] : [];
                //Cambiamos dependiento de empresa
                if (process.env.VUE_APP_CLIENT == "players_palace") {
                    physicalHeaders = physicalHeaders.filter(x => x.value != "trxDevice");
                }
                return physicalHeaders;
            }
            else if (this.report_type == 3)
                return LINK_TRANSACTION_HEADERS.ACCEPT;
            else 
            return ALL_TRANSACTION_HEADERS[this.type] ? ALL_TRANSACTION_HEADERS[this.type] : [];
        },
        menuModel() {
            if (this.report_type == 1) {
                return [
                    {label: 'Ver Detalles', icon: 'pi pi-fw pi-search', action: 'view-details'},
                    {label: 'Capturar', icon: 'pi pi-fw pi-money-bill', action: 'capture'},
                    {label: 'Reembolsar', icon: 'pi pi-fw pi-refresh', action: 'refund'},
                    {label: 'Reversar', icon: 'pi pi-fw pi-replay', action: 'reversal'},
                    {label: 'Void', icon: 'pi pi-fw pi-replay', action: 'void'},
                    {label: 'Enviar', icon: 'pi pi-fw pi-send', action: 'send'},
                    {label: 'Ver Ticket', icon: 'pi pi-fw pi-ticket', action: 'ticket'},
                ];
            }else if(this.report_type == 2) {
                return [
                    {label: 'Ver Detalles', icon: 'pi pi-fw pi-search', action: 'view-details'},
                    {label: 'Enviar', icon: 'pi pi-fw pi-send', action: 'send'}
                ];
            }else {
                return [];
            }
        },
    },
    async mounted() {
        try {
            this.loading = true;
            let user = new ERPUser(this.session);
            user.username = this.session.user;
            let my_branches = await user.access();
            this.branches.push({
                id_branch: 0,
                branch_name: "TODAS LAS SUCURSALES"
            })
            my_branches.forEach(branch => {
                this.branches.push(branch);
            });
            //!! Se cambia por que se agrega la funcionalidad de agregar todas las sucursales
            // this.branch_val = this.session.branch;
            this.branch_val = 0;
        } catch (error) {
            this.$toast.add(new ErrorToast(error));
        } finally {
            this.loading = false;
        }
    },
    methods: {
        getTotals(totals) {
            this.total = totals["Importe"];
            this.comissions = totals["Comision"];
            this.iva = totals["IVA Comision"];
            this.neto = totals["Pago Neto"];
        },
        openNew() {
            this.initial_date = new Date();
            this.final_date = new Date();
            this.entities = [];
            this.branch_val = 0;
            this.total = '$0.00';
            this.comissions = '$0.00';
            this.neto = '$0.00';
            this.iva = '$0.00';
        },
        async list() {
            this.loading = true;
            try {
                this.validate = validate(
                    {
                        initial_date: this.initial_date,
                        final_date: this.final_date
                    },
                    this.rules
                );
                if (!this.validate.valid) throw 'Favor de validar los campos';
                let payment = new PaymentParameters(this.session);
                if (this.report_type == 1)
                    this.entities = await payment.getTransactions(this.initial_date, this.final_date, this.branch_val, this.status);
                else if(this.report_type == 2)  
                    this.entities = await new BWPhysicalSale(this.session).getTransactions(this.initial_date, this.final_date, this.branch_val, this.status, this.type);
                else if(this.report_type == 3) 
                    this.entities = await new BWInvoice(this.session).getTransactions(this.initial_date, this.final_date, this.branch_val, this.status);
                else if(this.report_type == 4) 
                    this.entities = await  payment.getTransactionsall(this.initial_date, this.final_date, this.branch_val, this.status,this.type);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        viewDetails(transaction) {
            this.transaction = transaction;
            this.transactionView = true;
        },
        async capture(transaction) {
            this.loading = true;
            try {
                if (transaction.is_captured) 
                    throw "Error. Esta transaccion ya esta capturada"
                if (!this.validate.valid) throw 'Favor de validar los campos';
                let payment = new PaymentParameters(this.session);
                let response = await payment.captured(transaction.id, transaction.clientCode, transaction.authorized_amount.toString(), transaction.currency);
                    let message = PAYMENT_DICTIONARY_CAPTURE["201"].find(x => x.status == response.status);
                    this.paymentParameters.id = response.id;
                    this.$toast.add({
                    severity: 'success',
                    closable: true,
                    summary: response.status,
                    detail: message ? message.message_es : "No se encontró el mensaje en el diccionario",
                    life: 30000,
                });
                
            } catch (error) {
                    var errorReal =(error.response.data);
                    var posicionCaracter = errorReal.indexOf("{");
                    var posicionCaracter2 = errorReal.indexOf("}");
                    var response=JSON.parse(errorReal.substring(posicionCaracter, posicionCaracter2 + 1));
                    let message = (PAYMENT_DICTIONARY_CAPTURE["400"].find(x => x.status == response['status']
                    && x.reason ==  response['reason'])) || (PAYMENT_DICTIONARY_CAPTURE["502"].find(x => x.status == response['status']
                    && x.reason ==  response['reason']));
                    this.$toast.add({
                    severity:  response['status'] == "INVALID_REQUEST" ? 'error' :response['status'] == "SERVER_ERROR" ? 'error' : 'warn',
                    closable: true,
                    summary: response['status'],
                    detail: message ? message.message_es : "No se encontró el mensaje en el diccionario " + 
                    response['reason'],
                    life: 30000,
                        });
            } finally {
                this.loading = false;
            }
        },
        async refund(transaction) {
            this.loading = true;
            try {
                if (!this.validate.valid) throw 'Favor de validar los campos';
                let payment = new PaymentParameters(this.session);
                let response = await payment.refund(transaction.id, transaction.clientCode, transaction.authorized_amount.toString(), transaction.currency);
                let message = PAYMENT_DICTIONARY_REFUND["201"].find(x => x.status == response.status);
                this.paymentParameters.id = response.id;
                    this.$toast.add({
                    severity: 'success',
                    closable: true,
                    summary: response.status,
                    detail: message ? message.message_es : "No se encontró el mensaje en el diccionario",
                    life: 30000,
                });
            } catch (error) {
                    var errorReal =(error.response.data);
                    var posicionCaracter = errorReal.indexOf("{");
                    var posicionCaracter2 = errorReal.indexOf("}");
                    var response=JSON.parse(errorReal.substring(posicionCaracter, posicionCaracter2 + 1));
                    let message = (PAYMENT_DICTIONARY_REFUND["400"].find(x => x.status == response['status']
                    && x.reason ==  response['reason'])) || (PAYMENT_DICTIONARY_REFUND["502"].find(x => x.status == response['status']
                    && x.reason ==  response['reason']));
                    this.$toast.add({
                    severity:  response['status'] == "INVALID_REQUEST" ? 'error' :response['status'] == "SERVER_ERROR" ? 'error' : 'warn',
                    closable: true,
                    summary: response['status'],
                    detail: message ? message.message_es : "No se encontró el mensaje en el diccionario " + 
                    response['reason'],
                    life: 30000,
                        });
            } finally {
                this.loading = false;
            }
        },
        async voidTransaction(transaction) {
            this.loading = true;
            alert('void');
            try {
                let payment = new PaymentParameters(this.session);
                let response = await payment.void(transaction.id, transaction.clientCode, transaction.authorized_amount.toString(), transaction.currency);
                //!! HAY QUE CAMBIAR AL DE VOID CHAVOS
                    let message = PAYMENT_DICTIONARY_REFUND["201"].find(x => x.status == response.status);
                    this.paymentParameters.id = response.id;
                    this.$toast.add({
                    severity: 'success',
                    closable: true,
                    summary: response.status,
                    detail: message ? message.message_es : "No se encontró el mensaje en el diccionario",
                    life: 30000,
                });
            } catch (error) {
                
                    var errorReal =(error.response.data);
                    var posicionCaracter = errorReal.indexOf("{");
                    var posicionCaracter2 = errorReal.indexOf("}");
                    var response=JSON.parse(errorReal.substring(posicionCaracter, posicionCaracter2 + 1));
                     let message = (PAYMENT_DICTIONARY_REFUND["400"].find(x => x.status == response['status']
                    && x.reason ==  response['reason'])) || (PAYMENT_DICTIONARY_REFUND["502"].find(x => x.status == response['status']
                    && x.reason ==  response['reason']));
                    this.$toast.add({
                    severity:  response['status'] == "INVALID_REQUEST" ? 'error' :response['status'] == "SERVER_ERROR" ? 'error' : 'warn',
                    closable: true,
                    summary: response['status'],
                    detail: message ? message.message_es : "No se encontró el mensaje en el diccionario " + 
                    response['reason'],
                    life: 30000,
                      });
            } finally {
                this.loading = false;
            }
        },
        async reversal(transaction) {
            this.loading = true;
            try {
                if (transaction.is_captured) 
                    throw "Error. Esta transaccion ya esta capturada, no se puede dar reversa"
                if (!this.validate.valid) throw 'Favor de validar los campos';
                let payment = new PaymentParameters(this.session);
                let response = await payment.reversal(transaction.id, transaction.clientCode, transaction.authorized_amount.toString(), "PRUEBA");
                let message = PAYMENT_DICTIONARY_REVERSAL["201"].find(x => x.status == response.status);
                    this.paymentParameters.id = response.id;
                    this.$toast.add({
                    severity: 'success',
                    closable: true,
                    summary: response.status,
                    detail: message ? message.message_es : "No se encontró el mensaje en el diccionario",
                    life: 30000,
                });
            } catch (error) {
                if (error == "Error. Esta transaccion ya esta capturada, no se puede dar reversa") {
                    this.$toast.add({
                        severity:  'error',
                        closable: true,
                        summary: 'ERROR',
                        detail: "Error. Esta transaccion ya esta capturada, no se puede dar reversa",
                        life: 30000,
                    });
                }else {
                    var errorReal =(error.response.data);
                    var posicionCaracter = errorReal.indexOf("{");
                    var posicionCaracter2 = errorReal.indexOf("}");
                    var response=JSON.parse(errorReal.substring(posicionCaracter, posicionCaracter2 + 1));
                    let message = (PAYMENT_DICTIONARY_REVERSAL["400"].find(x => x.status == response['status']
                    && x.reason ==  response['reason'])) || (PAYMENT_DICTIONARY_REVERSAL["502"].find(x => x.status == response['status']
                    && x.reason ==  response['reason']));
                    this.$toast.add({
                        severity:  response['status'] == "INVALID_REQUEST" ? 'error' :response['status'] == "SERVER_ERROR" ? 'error' : 'warn',
                        closable: true,
                        summary: response['status'],
                        detail: message ? message.message_es : "No se encontró el mensaje en el diccionario " + 
                        response['reason'],
                        life: 30000,
                    });
                }
            } finally {
                this.loading = false;
            }
        },
        async send(transaction) {
            this.loading = true;
            try {
                let payment = new PaymentParameters(this.session);
                await payment.send(transaction.id, transaction.clientCode, transaction.billTo_email, transaction.email);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Envio',
                    detail: 'Se ha enviado el ticket a su correo' ,
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        ticket(transaction) {
            window.open(this.$config.file_server +"/file/ticket.html?id=" + transaction.id,"_blank") 
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
    },
};
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}

  .scrollable-table::-webkit-scrollbar {
	width: 5px;
    height: 8px;
	}

	/* Track */
.scrollable-table::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 50px;
	}

	/* Handle */
.scrollable-table::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 50px;
	}

	/* Handle on hover */
.scrollable-table::-webkit-scrollbar-thumb:hover {
	background: #555;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2)
	}
</style>
