import axios from 'axios';

export class BWPhysicalSale { 
    constructor(session) {
      this.user = session ? session.user : null;
      this.id_company = session ? session.company : null;
      this.id_branch = session ? session.branch : null;
    }



    async getTransactions(initial_date, final_date, branch, status, type) {
      branch = branch ?? this.id_branch;
      let response = await axios.post('BusinessWallet/PhysicalSale/list', {
        initial_date: initial_date,
        final_date: final_date,
        status: status,
        type: type
      }, {
          headers: {
              user: this.user,
              company: this.id_company,
              branch: branch,
          }
      });
      return response.data;
    }
}